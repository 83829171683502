import React from "react";
import { Col } from "react-bootstrap";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import ListingCardProps from "../../interfaces/components/ListingCard/ListingCardProps";
import "./ListingCard.css";

const ListingCard: React.FC<ListingCardProps> = ({
  icon,
  title,
  isFavourite,
  text,
  features,
  redirectLink,
  active,
  tags = null,
}) => {
  return (
    <Col className="deal-card">
      <div className="deal-content">
        {/* Title with Bookmark */}
        <div className="deal-header">
          <h4 className="deal-title">
            <a className="custom-link" href={redirectLink}>
              {title} {active ? "" : " - (NOT PUBLISHED)"}
            </a>
          </h4>
          {isFavourite ? (
            <BsBookmarkFill className="bookmark-icon" />
          ) : (
            <BsBookmark className="bookmark-icon" />
          )}
        </div>

        {/* Description */}
        <p className="deal-description">{text}</p>
        <hr className="deal-divider" />

        {/* Feature Details */}
        <div className={`deal-details ${features.length > 2 ? "three-columns" : "two-columns"}`}>
          {features.slice(0, 3).map((feature, index) => (
            <div key={index}>
              <span className="detail-label">{feature?.featureTitle}:</span>
              <br />
              <span className="detail-value">{feature?.featureAmount}</span>
            </div>
          ))}
        </div>
        {tags && tags.length > 0 && (
          <div className="deal-tags">
            {tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag.name}
              </span>
            ))}
          </div>
        )}
      </div>
    </Col>
  );
};

export default ListingCard;
